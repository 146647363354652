import { Injectable } from "@angular/core";
import { RemovedInvoicedateListApiService } from "src/api/services/removed-invoicedates-api.service";
import { AppState } from "../app.state";
import { State, Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { RemoveInvoiceDateForProductIndividual } from "./state-models/removed-invoicedate-data";
import { ChangeRemovedInvoicedateListFilterExpressionAction, 
         ChangeRemovedInvoicedateListPageAction, 
         ChangeRemovedInvoicedateListSortExpressionAction, 
         ChangeRemovedInvoicedateListTotalCountAction, 
         LoadRemovedInvoicedateListAction } from "./action";
import { Paging } from "../common/state-models/paging";
import { Loadable } from "../common/loadable";


@Injectable()
export class RemovedInvoicedateListStateService {
    constructor(
        private apiService: RemovedInvoicedateListApiService,
        private appStore: Store<AppState>,
        private appState: State<AppState>
    ) { }

    @Loadable()
    async loadRemovedInvoicedatesList(
        paging: Paging,
        sortExpression: string,
        filterExpression: string) {
        const payload = {           
            paging: {
                page: paging.page,
                offset: paging.offset
            },
            order: sortExpression,
            filter: filterExpression,
        };
        const removedInvoicedatesList = await this.apiService.getRemovedInvoicedatesList(payload);
        this.appStore.dispatch(new LoadRemovedInvoicedateListAction(removedInvoicedatesList.list));
        this.appStore.dispatch(new ChangeRemovedInvoicedateListTotalCountAction(removedInvoicedatesList.totalCount));    
    }

    getInvoicedDates(productIndividualId: number){
        const payload = {productIndividualId: Number(productIndividualId)}
        return this.apiService.getInvoiceDates(payload);
    }

    removeInvoiceDate(removeInvoiceDate: RemoveInvoiceDateForProductIndividual) {
        return this.apiService.removeInvoiceDate(removeInvoiceDate);
    }

    getRemovedInvoiceDateList(): Observable<RemoveInvoiceDateForProductIndividual[]> {
        return this.appStore.select(state => state.removedInvoicedates.removedInvoicedatesList);
    }

    getSortingExpression(): Observable<string> {
        return this.appStore.select(state => state.removedInvoicedates.removedInvoicedatesSortExpression);
    }

    sort(sortExpression: string) {        
        this.appStore.dispatch(new ChangeRemovedInvoicedateListSortExpressionAction(sortExpression));
    }

    getFilterExpression(): Observable<string> {
        return this.appStore.select(state => state.removedInvoicedates.removedInvoicedatesFilterExpression);
    }

    filter(filterExpression: string) {        
        this.appStore.dispatch(new ChangeRemovedInvoicedateListFilterExpressionAction(filterExpression));
    }

    getPaging(): Observable<Paging> {
        return this.appStore.select(state => state.removedInvoicedates.removedInvoicedatesPaging);
    }

    goToPage(page: number) {      
        this.appStore.dispatch(new ChangeRemovedInvoicedateListPageAction(page));
    }

    getTotalCount(): Observable<number> {
        return this.appStore.select(state => state.removedInvoicedates.removedInvoicedatesTotalCount);
    }

    clearFilterAndSorting() {
        [
            new ChangeRemovedInvoicedateListSortExpressionAction(null),
            new ChangeRemovedInvoicedateListFilterExpressionAction(null)
        ].forEach(action=>this.appStore.dispatch(action));
    }
}